import { Product } from './types'

const MAX_ALLOWED_QUANTITY_PER_CART = 20000

const getLimitPerOrder = (product: Product) => {
  const { limitPerOrder, supplierPrices, discount } = product

  if (discount) {
    return limitPerOrder == undefined || limitPerOrder == null
      ? MAX_ALLOWED_QUANTITY_PER_CART
      : limitPerOrder
  }

  return !supplierPrices[0].limitPerOrder
    ? MAX_ALLOWED_QUANTITY_PER_CART
    : supplierPrices[0].limitPerOrder
}

export const getProductMaxOrderLimit = (product: Product): number => {
  const [{ getMoreForLess, inStockQuantity, supplierType, offerType, availableQuantity }] =
    product.supplierPrices

  if (getMoreForLess) {
    const limitInUnits = getMoreForLess.limit * product.packageNumberOfItems!
    const stock = inStockQuantity || 0

    return Math.min(stock, limitInUnits)
  }

  if (!(product.discount || product.inOfertao)) {
    if (supplierType === 'Estoque') {
      return inStockQuantity || 0
    }
    return MAX_ALLOWED_QUANTITY_PER_CART
  }

  const availableQuantityValue =
    !offerType || offerType === 3
      ? inStockQuantity ?? MAX_ALLOWED_QUANTITY_PER_CART
      : availableQuantity ?? MAX_ALLOWED_QUANTITY_PER_CART

  return Math.min(getLimitPerOrder(product), availableQuantityValue)
}

export const getMaxLimitForMode = (isPackageMode: boolean, product: Product): number => {
  const maxLimit = getProductMaxOrderLimit(product)

  if (isPackageMode) {
    const packageNumberOfItems = product.packageNumberOfItems || 1
    const maxLimitPackage = Math.floor(maxLimit / packageNumberOfItems)

    return maxLimitPackage
  }

  return maxLimit
}

export const getCartQuantityLimitReached = (
  isPackageMode: boolean,
  product: Product,
  quantity: number
): number => {
  const maxLimit = getProductMaxOrderLimit(product)

  if (isPackageMode) {
    const packageNumberOfItems = product.packageNumberOfItems || 1
    const maxLimitPackage = Math.floor(maxLimit / packageNumberOfItems)
    return quantity > maxLimitPackage ? maxLimitPackage : 0
  }

  return quantity > maxLimit ? maxLimit : 0
}
