import { useCallback, useEffect, useState } from 'react'

import { Spinner } from '../atoms/Spinner'
import { Home } from '../templates/Home'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { getOfertoes } from '../../services/productApi'
import { store } from '../../store'
import { Product } from '../../store/data/types'
import { latestRatingThunk } from '../../store/slices/rating'
import { loginFromCode, sessionActions } from '../../store/slices/session'

const HOUR_GAP_BETWEEN_LOGIN_REQUIREMENTS = 1000 * 60 * 60 * 3 // 1000 ms == 1 sec, 60 sec == 1 min, 60 min = 1h, 3h

export const Welcome = () => {
  const dispatch = useAppDispatch()
  const [welcomeOffers, merchantCode, regionId] = useAppSelector((state) => [
    state.welcomeOffers,
    state.session.merchantCode,
    state.session.regionId,
  ])
  const [ofertaoProducts, setOfertaoProducts] = useState<Product[]>([])

  const getOfertaoProducts = async () => {
    const ofertao = await getOfertoes()
    setOfertaoProducts(ofertao)
  }

  const showLoginModal = useCallback(() => dispatch(sessionActions.showModal(true)), [dispatch])

  useEffect(() => {
    const { merchantName, lastLoginRequirement, regionId } = store.getState().session
    if (merchantName && regionId) {
      if (merchantCode) dispatch(latestRatingThunk(merchantCode))
      return
    }
    if (merchantCode) {
      dispatch(loginFromCode(merchantCode))
      return
    }
    const waitToShowModal = lastLoginRequirement
      ? HOUR_GAP_BETWEEN_LOGIN_REQUIREMENTS -
        (new Date().getTime() - new Date(lastLoginRequirement).getTime())
      : 1500
    const timeoutId = setTimeout(showLoginModal, waitToShowModal > 0 ? waitToShowModal : 0)
    return () => clearTimeout(timeoutId)
  }, [dispatch, merchantCode])

  useEffect(() => {
    getOfertaoProducts()
  }, [merchantCode, regionId])

  const offersWithoutOfertao = welcomeOffers.filter(({ name }) => name !== 'Ofertão')

  return (
    <>
      {offersWithoutOfertao.length ? (
        <Home
          groups={offersWithoutOfertao}
          context={['welcome']}
          ofertaoProducts={ofertaoProducts}
        />
      ) : (
        <Spinner />
      )}
    </>
  )
}
