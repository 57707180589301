import { AppDispatch, store } from '../index'
import { MINUTE_MS } from '../../common/constants'
import { loginFromCode, sessionActions } from '../slices/session'

export const loginAgainIfNeeded = (dispatch: AppDispatch) => {
  return () => {
    const session = store.getState().session
    if (!session.merchantCode) {
      if (session.merchantName) {
        dispatch(sessionActions.clearMerchantData())
      }
      return
    }
    const mustLogin = () => {
      if (!session.loginDatetime) {
        return true
      } else {
        const dtDiff = Date.now() - Date.parse(session.loginDatetime)
        return dtDiff > 5 * MINUTE_MS
      }
    }
    if (mustLogin()) {
      dispatch(loginFromCode(session.merchantCode))
    }
  }
}
